import {BaseService, Body, GET, Headers, POST, Path, Response, PATCH, Query, DELETE} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {
    FilterRemoteDeviceLogRequest,
    FilterRemoteDevicesRequest
} from '../../../models/requests/filter-remote-devices-request';
import {RemoteDevice} from '../../../models/remote-device';
import {RemoteDeviceLog} from '../../../models/remote-device-log';
import {EditDeviceRequest} from '../../../models/requests/edit-device-request';
import {RemoteDeviceType} from '../../../models/remote-device-type';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';
import {
    RemoteDeviceScheduledCommand,
    RemoteDeviceScheduledCommandDto
} from '../../../models/remote-device-scheduled-command';

const base = '/remoteDevices';
const scheduledCommandsBase = '/remoteDevicesScheduledCommands';

export class RemoteDevicesService extends BaseService {

    @POST(`${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(RemoteDevice)
    async filter(@Body request: FilterRemoteDevicesRequest): Promise<Response<ListedResponse<RemoteDevice>>> {
        return <Response>{};
    }

    @GET(`${base}/{id}`)
    @Headers({...ACCEPT_JSON})
    @ToData(RemoteDevice)
    async get(@Path('id') id: number): Promise<Response<RemoteDevice>> {
        return <Response>{};
    }

    @POST(`${base}/{id}/logs/search`)
    @Headers({...ACCEPT_JSON})
    async getLogs(@Path('id') id: number, @Body request: FilterRemoteDeviceLogRequest):
        Promise<Response<ListedResponse<RemoteDeviceLog>>> {
        return <Response>{};
    }

    @PATCH(`${base}/{id}`)
    @Headers({...ACCEPT_JSON})
    async editDevice(@Path('id') id: number, @Body request: EditDeviceRequest): Promise<Response> {
        return <Response>{};
    }

    @GET(`${base}/types/{id}`)
    @Headers({...ACCEPT_JSON})
    async getDeviceType(@Path('id') id: number): Promise<Response<RemoteDeviceType>> {
        return <Response>{};
    }

    @GET(`${base}/types`)
    @Headers({...ACCEPT_JSON})
    async getAllTypes(): Promise<Response<RemoteDeviceType[]>> {
        return <Response>{};
    }

    @POST(`${base}/types`)
    @Headers({...ACCEPT_JSON})
    async saveDeviceType(@Body request: RemoteDeviceType): Promise<Response> {
        return <Response>{};
    }

    @POST(`${base}/{id}/runCommand`)
    async runCommand(@Path('id') deviceId: number, @Query('commandId') commandId: number,
                     @Body parameters: { [key: string]: any }) {
        return <Response>{};
    }

    @POST(`${base}/logs/{id}/rerunCommand`)
    async rerunCommand(@Path('id') logId: number) {
        return <Response>{};
    }

    @POST(`${base}/{id}/resetConnectivity`)
    async resetConnectivity(@Path('id') deviceId: number) {
        return <Response>{};
    }

    @POST(`${base}/{id}/synchronize`)
    async synchronizeDevice(@Path('id') deviceId: number) {
        return <Response>{};
    }

    @GET(`${scheduledCommandsBase}`)
    @Headers({...ACCEPT_JSON})
    async getAllScheduledCommands(): Promise<Response<RemoteDeviceScheduledCommand[]>> {
        return <Response>{};
    }

    @POST(`${scheduledCommandsBase}/{type}`)
    async scheduleNewCommand(@Path('type') type: string, @Body request: RemoteDeviceScheduledCommandDto) {
        return <Response>{};
    }

    @DELETE(`${scheduledCommandsBase}/{id}`)
    async deleteScheduledCommand(@Path('id') id: number) : Promise<Response<RemoteDeviceScheduledCommandDto>> {
        return <Response>{};
    }

    @GET(`${scheduledCommandsBase}/{id}`)
    @ToData(RemoteDeviceScheduledCommandDto)
    async getScheduledCommandById(@Path('id') id: number): Promise<Response<RemoteDeviceScheduledCommandDto>> {
        return <Response>{};
    }

}
